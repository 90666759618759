// export const baseUrl = "http://qiaolianyun.com"
// export const baseUrl = "http://localhost:3000"

//桥联
export const companyName = "山东桥联智能科技有限公司"
export const companyName1 = "桥联智能"
export const phone1 = "185 6160 1011"
export const phone2 = "136 7881 8516"
export const phone3 = "136 7881 8516"
export const weChat = "13678818516"
export const contact = "马经理"
export const companyProfile1 = "桥联智能成立于2020年初，是一家专业从事行业应用软件开发，集研发、销售、实施服务为一体的技术服务型公司。公司业务面向智慧市场、智慧交通、智慧乡镇、智慧审计、教育、人工智能及物联网共享公共服务平台的综合信息化软件提供商。桥联智能，为全国市场监督管理局提供“市场监管卫士”服务，产品涵盖智慧市场、智慧食安、校园食安卫士、智慧药房、AI智能分析、特种设备监督等全套的市场监管信息化产品。以助手型发挥信息化的督导、督管、督查机制;为企业者提供信息化“帮手”; 为消费者提供数据查询“助手”;拱数据查询“助手”;为执法者提供数据”抓手”。实现打造“让市场更诚信，让生活更健康”的市场监管信息化服务平台提供商。公司现有市场监管食安助手云计算服务应用平台、“智慧食安”校园风险预警监督管理平台、“ 智慧药安”智能巡查巡检数据普查平台、“智慧市场”综合监管执法数据分析平台、投诉举报大数据风险预警监测分析平台、特种设备维保数据综合监管督查平台等多项自主研发的软件产品，聚焦智慧市场、食品药品行业信息化监管，建立从科研到产品服务的整套体系解决方案，并与全国多家政府单位建立长期软件服务合作。"
export const companyProfile2 = "随着公司不断引进先进管理理念，秉承“以人为本、 诚信经营”的宗旨，汇聚了- -批朝气蓬勃、高素质的技术精英人才，拥有专业的技术研发团队和经验丰富的项目管理服务人员，竭诚为食药部门、市场部门、审计部门、政府部门等行业用户提供全方位的信息化解决方案及服务。"
export const address = "山东省青岛市黄岛区江山南路480号讯飞未来港7楼"
export const mobileUrl = "http://qiaolianyun.com/mobile"
export const baseUrl = "http://qiaolianyun.com"

//安瑞
// export const companyName = "青岛安瑞信息技术有限公司"
// export const companyName1 = "安瑞科技"
// export const phone1 = "400-032-0005"
// export const phone2 = "186 6988 6504"
// export const phone3 = "186 6988 6504"
// export const weChat = "18669886504"
// export const contact = "邓经理"
// export const companyProfile1 = "青岛安瑞信息技术有限公司成立于2011年，坐落于美丽的海滨城市青岛，安瑞科技是一家专业提供数字乡村、智慧政务、智慧城市解决方案的高科技公司，安瑞科技拥有深厚的技术积累，并长期投入于新技术与新场景的应用研究，不断增强数字技术服务行业领先的全栈式服务能力，涵盖智能算法、卫星遥感、智能感知、智能决策、大数据分析、智能内容生成和智能内容增强等关键技术应用场景，相关产品与解决方案深受客户与合作伙伴好评。"
// export const companyProfile2 = "安瑞倡导“发展”的技术服务赋能观，并积极参与国家重点规划领域内的业务场景应用研究及落地实施，积极参与制定相关行业标准规范，积极与国内同行业公司交流合作，为推动产业发展而不断努力。"
// export const address = "山东省青岛市黄岛区江山南路480号讯飞未来港7楼"
// export const mobileUrl = "http://qiaolianyun.com/anruimobile"
// export const baseUrl = "http://qiaolianyun.com/anrui"

//世纪亿联
// export const companyName = "青岛世纪亿联信息科技有限公司"
// export const companyName1 = "世纪亿联"
// export const phone1 = "186 6988 2666"
// export const phone2 = "186 7893 3688"
// export const phone3 = "186 6988 2666"
// export const weChat = "18669882666"
// export const contact = "周经理"
// export const companyProfile1 = "青岛世纪亿联信息科技有限公司成立于2015年，坐落于美丽的海滨城市青岛，世纪亿联是一家专业提供数字乡村、智慧政务、智慧城市解决方案的高科技公司，世纪亿联拥有深厚的技术积累，并长期投入于新技术与新场景的应用研究，不断增强数字技术服务行业领先的全栈式服务能力，涵盖智能算法、卫星遥感、智能感知、智能决策、大数据分析、智能内容生成和智能内容增强等关键技术应用场景，相关产品与解决方案深受客户与合作伙伴好评。"
// export const companyProfile2 = "世纪亿联倡导“发展”的技术服务赋能观，并积极参与国家重点规划领域内的业务场景应用研究及落地实施，积极参与制定相关行业标准规范，积极与国内同行业公司交流合作，为推动产业发展而不断努力。"
// export const address = "山东省青岛市黄岛区江山南路480号讯飞未来港7楼"
// export const mobileUrl = "http://qiaolianyun.com/shijiyilianmobile"
// export const baseUrl = "http://qiaolianyun.com/shijiyilian"

//小飞
// export const companyName = "青岛小飞智能科技工程有限公司"
// export const companyName1 = "小飞智能"
// export const phone1 = "189 1324 9155"
// export const phone2 = "186 6395 7222"
// export const phone3 = "189 1324 9155"
// export const weChat = "18913249155"
// export const contact = "范经理"
// export const companyProfile1 = "青岛小飞智能科技工程有限公司成立于2021年，坐落于美丽的海滨城市青岛，小飞智能是一家专业提供数字乡村、智慧政务、智慧城市解决方案的高科技公司，小飞智能拥有深厚的技术积累，并长期投入于新技术与新场景的应用研究，不断增强数字技术服务行业领先的全栈式服务能力，涵盖智能算法、卫星遥感、智能感知、智能决策、大数据分析、智能内容生成和智能内容增强等关键技术应用场景，相关产品与解决方案深受客户与合作伙伴好评。"
// export const companyProfile2 = "小飞智能倡导“发展”的技术服务赋能观，并积极参与国家重点规划领域内的业务场景应用研究及落地实施，积极参与制定相关行业标准规范，积极与国内同行业公司交流合作，为推动产业发展而不断努力。"
// export const address = "山东省青岛市黄岛区江山南路480号讯飞未来港7楼"
// export const mobileUrl = "http://qiaolianyun.com/xiaofeimobile"
// export const baseUrl = "http://qiaolianyun.com/xiaofei"

//云科睿
// export const companyName = "济南云科睿信息科技有限公司"
// export const companyName1 = "云科睿"
// export const phone1 = "189 5312 9500"
// export const phone2 = "156 0531 0619"
// export const phone3 = "189 5312 9500"
// export const weChat = "18953129500"
// export const contact = "王经理"
// export const companyProfile1 = "济南云科睿信息科技有限公司于2015年8月注册成立。是一家致力于智慧城市产品及解决方案的专业公司，其宗旨是不断提升视频管理的应用技术和网络处理技术，为各行业用户提供先进的网络视频应用解决方案。伴随着以智能化、高清化为代表的信息技术革命浪潮，云科睿结合自身专业的智能化、高清化、流媒体、云存储等视频监控应用技术，通过公司全体员工孜孜不倦的努力之下，成为智慧城市产品及解决方案提供商。"
// export const companyProfile2 = " 云科睿提供的视频应用解决方案以行业特性为基础，以合作软件平台为载体，采用统一接入标准、分布式架构，以及业界领先的计算机图像处理、模式识别技术，通过合理灵活的分层结构，利用云计算对大数据进行挖掘分析，高度集成各类子系统，支持100多个国内主流厂家前端的百万级接入，对各行业用户开发出符合其日常工作习惯的软件产品，在深入了解客户需求的基础上有效融合视频摘要、视频检索、图像增强、人脸识别等智能识别算法，通过大量的案例经验与完整丰富的项目积累，针对性的打造了食药监执法平台、旅游监控平台、录播教室、远程医疗系统、手机看店、社会资源接入等专业解决方案，积极推动智慧城市、平安城市、智慧校园、明厨亮灶等应用领域建设，为食药监、交通、能源、农业、校园、环保及电信运营商等行业用户提供大规模联网、大范围整合、大容量承载并结合行业深度应用的专业化音视频监控整体解决方案。云科睿作为本土的自主高新技术品牌，依靠高稳定性的平台、领先的研发能力、标准化的专业服务、完善的业务流程、强大的维保团队持续提升品牌价值，赢得了来自不同行业客户的高度信赖，以“诚信、优质、专业、敬业”的口碑闻名业内。公司以“为和谐社会创造智慧生活”为品牌使命，自品牌诞生以来，始终专注于国家信息化建设服务领域，面向平安城市、智慧校园、智慧城市建设的各行客户提供核心技术产品与全面的行业解决方案，全力提升社会管理水准，力求打造为智慧城市建设领军品牌。"
// export const address = "山东省济南市高新区新泺大街2117号济南大数据产业基地1402室"
// export const mobileUrl = "http://qiaolianyun.com/yunkeruimobile"
// export const baseUrl = "http://qiaolianyun.com/yunkerui"

//联通
// export const companyName = "中国联合网络通信集团有限公司"
// export const companyName1 = "中国联通"
// export const phone1 = "185 6160 1011"
// export const phone2 = "136 7881 8516"
// export const phone3 = "186 6988 6504"
// export const weChat = "18669886504"
// export const contact = ""
// export const companyProfile1 = ""
// export const companyProfile2 = ""
// export const address = "山东省济南市市中区经三路77号"
// export const mobileUrl = "http://qiaolianyun.com/unicomMobile"
// export const baseUrl = "http://qiaolianyun.com/unicom"



//因特信息
// export const companyName = "青岛因特信息科技有限责任公司"
// export const companyName1 = "因特信息"
// export const phone1 = "158 5328 8925"
// export const phone2 = "158 5328 8925"
// export const phone3 = "158 5328 8925"
// export const weChat = "15853288925"
// export const contact = "李总"
// export const companyProfile1 = "青岛因特信息科技成立于2015年，是一家专业从事行业应用软件开发的公司，集研发、销售、实施服务为一体的技术服务型公司。技术开发团队经验丰富，公司业务服务于政企以及教育等行业领域，涉及企业及学校管理系统、商用高端APP定制开发、商城开发、网站开发、微信公众号及小程序等移动互联网应用开发，面向智慧市场、智慧交通、智慧乡镇、智慧审计、教育、人工智能及物联网共享公共服务平台的综合信息化软件提供商。青岛因特信息科技，为全国市场监督管理局提供“市场监管卫士”服务，产品涵盖智慧市场、智慧食安、校园食安卫士、智慧药房、AI智能分析、特种设备监督等全套的市场监管信息化产品。以助手型发挥信息化的督导、督管、督查机制;为企业者提供信息化“帮手”; 为消费者提供数据查询“助手”;拱数据查询“助手”;为执法者提供数据”抓手”。实现打造“让市场更诚信，让生活更健康”的市场监管信息化服务平台提供商。公司现有市场监管食安助手云计算服务应用平台、“智慧食安”校园风险预警监督管理平台、“ 智慧药安”智能巡查巡检数据普查平台、“智慧市场”综合监管执法数据分析平台、投诉举报大数据风险预警监测分析平台、特种设备维保数据综合监管督查平台等多项自主研发的软件产品，聚焦智慧市场、食品药品行业信息化监管，建立从科研到产品服务的整套体系解决方案，并与全国多家政府单位建立长期软件服务合作。"
// export const companyProfile2 = "随着公司不断引进先进管理理念，秉承“以人为本、 诚信经营”的宗旨，汇聚了一批朝气蓬勃、高素质的技术精英人才，拥有专业的技术研发团队和经验丰富的项目管理服务人员，竭诚为食药部门、市场部门、审计部门、政府部门等行业用户提供全方位的信息化提供IT咨询，业务规划，行业解决方案，定制化开发与实施到系统维护服务的一站式全生命周期，价值链的IT解决方案及服务。"
// export const address = "山东省青岛市黄岛区峨眉山路396号6-2栋5楼"
// export const mobileUrl = "http://yintekj.top/mobile"
// export const baseUrl = "http://yintekj.top"
// export const baseUrl = "http://localhost:3001"

//欧嘉悦
// export const companyName = "青岛欧嘉悦网络科技有限公司"
// export const companyName1 = "欧嘉悦"
// export const phone1 = "153 1879 5766"
// export const phone2 = "153 1879 5766"
// export const phone3 = "153 1879 5766"
// export const weChat = "15318795766"
// export const contact = "付总"
// export const companyProfile1 = "青岛欧嘉悦网络科技成立于2020年，是一家专业从事行业应用软件开发的公司，集研发、销售、实施服务为一体的技术服务型公司。技术开发团队经验丰富，公司业务服务于政企以及教育等行业领域，涉及企业及学校管理系统、商用高端APP定制开发、商城开发、网站开发、微信公众号及小程序等移动互联网应用开发，面向智慧市场、智慧交通、智慧乡镇、智慧审计、教育、人工智能及物联网共享公共服务平台的综合信息化软件提供商。青岛欧嘉悦网络科技，为全国市场监督管理局提供“市场监管卫士”服务，产品涵盖智慧市场、智慧食安、校园食安卫士、智慧药房、AI智能分析、特种设备监督等全套的市场监管信息化产品。以助手型发挥信息化的督导、督管、督查机制;为企业者提供信息化“帮手”; 为消费者提供数据查询“助手”;拱数据查询“助手”;为执法者提供数据”抓手”。实现打造“让市场更诚信，让生活更健康”的市场监管信息化服务平台提供商。公司现有市场监管食安助手云计算服务应用平台、“智慧食安”校园风险预警监督管理平台、“ 智慧药安”智能巡查巡检数据普查平台、“智慧市场”综合监管执法数据分析平台、投诉举报大数据风险预警监测分析平台、特种设备维保数据综合监管督查平台等多项自主研发的软件产品，聚焦智慧市场、食品药品行业信息化监管，建立从科研到产品服务的整套体系解决方案，并与全国多家政府单位建立长期软件服务合作。"
// export const companyProfile2 = "随着公司不断引进先进管理理念，秉承“以人为本、 诚信经营”的宗旨，汇聚了一批朝气蓬勃、高素质的技术精英人才，拥有专业的技术研发团队和经验丰富的项目管理服务人员，竭诚为食药部门、市场部门、审计部门、政府部门等行业用户提供全方位的信息化提供IT咨询，业务规划，行业解决方案，定制化开发与实施到系统维护服务的一站式全生命周期，价值链的IT解决方案及服务。"
// export const address = "山东省青岛市黄岛区峨眉山路396号6-2栋5楼"
// export const mobileUrl = "http://ojywl.top/ojymobile"
// export const baseUrl = "http://ojywl.top/ojy"
// export const baseUrl = "http://localhost:3001"

//中微世纪
// export const companyName = "青岛中微世纪信息科技有限公司"
// export const companyName1 = "中微世纪"
// export const phone1 = "188 6395 1252"
// export const phone2 = "188 6395 1252"
// export const phone3 = "188 6395 1252"
// export const weChat = "18863951252"
// export const contact = "高总"
// export const companyProfile1 = "青岛中微世纪信息科技有限公司，是一家专业从事行业应用软件开发的公司，集研发、销售、实施服务为一体的技术服务型公司。技术开发团队经验丰富，公司业务服务于政企以及教育等行业领域，涉及企业及学校管理系统、商用高端APP定制开发、商城开发、网站开发、微信公众号及小程序等移动互联网应用开发，面向智慧市场、智慧交通、智慧乡镇、智慧审计、教育、人工智能及物联网共享公共服务平台的综合信息化软件提供商。青岛因特信息科技，为全国市场监督管理局提供“市场监管卫士”服务，产品涵盖智慧市场、智慧食安、校园食安卫士、智慧药房、AI智能分析、特种设备监督等全套的市场监管信息化产品。以助手型发挥信息化的督导、督管、督查机制;为企业者提供信息化“帮手”; 为消费者提供数据查询“助手”;拱数据查询“助手”;为执法者提供数据”抓手”。实现打造“让市场更诚信，让生活更健康”的市场监管信息化服务平台提供商。公司现有市场监管食安助手云计算服务应用平台、“智慧食安”校园风险预警监督管理平台、“ 智慧药安”智能巡查巡检数据普查平台、“智慧市场”综合监管执法数据分析平台、投诉举报大数据风险预警监测分析平台、特种设备维保数据综合监管督查平台等多项自主研发的软件产品，聚焦智慧市场、食品药品行业信息化监管，建立从科研到产品服务的整套体系解决方案，并与全国多家政府单位建立长期软件服务合作。"
// export const companyProfile2 = "随着公司不断引进先进管理理念，秉承“以人为本、 诚信经营”的宗旨，汇聚了一批朝气蓬勃、高素质的技术精英人才，拥有专业的技术研发团队和经验丰富的项目管理服务人员，竭诚为食药部门、市场部门、审计部门、政府部门等行业用户提供全方位的信息化提供IT咨询，业务规划，行业解决方案，定制化开发与实施到系统维护服务的一站式全生命周期，价值链的IT解决方案及服务。"
// export const address = "山东省青岛市黄岛区峨眉山路396号6-2栋503户"
// export const mobileUrl = "http://qdzwsj.cn/mobile"
// export const baseUrl = "http://qdzwsj.cn"
// export const baseUrl = "http://localhost:3001"