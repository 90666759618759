import React,{ useState, useEffect} from 'react'
import qlLogo from './imgs/qlLogo.png'
import arLogo from './imgs/arLogo.png'
import sjylLogo from './imgs/sjylLogo.png'
import xfLogo from './imgs/xfLogo.png'
import ykrLogo from './imgs/ykrLogo.png'
import ltLogo from './imgs/ltLogo.png'
import itxxLogo from './imgs/itxxLogo.png'
import ojyLogo from './imgs/ojyLogo.png'
import zwsjLogo from './imgs/zwsjLogo.png'
import weixin from './imgs/weixin.png'
import phone from './imgs/phone.png'
import weixin1 from './imgs/weixin-1.png'
import phone1 from './imgs/phone-1.png'
import code1 from './imgs/code-1.png'
import code from './imgs/code.png'
import top from './imgs/top.png'
import border1 from './imgs/background1.png'
import border2 from './imgs/background2.png'
import border3 from './imgs/background3.png'
import p1 from './imgs/p1.png'
import arWeCode from './imgs/arWeCode.png'
import p2 from './imgs/p2.png'
import arP2 from './imgs/arP2.png'
import sjylP1 from './imgs/sjylP1.png'
import sjylP2 from './imgs/sjylP2.png'
import xfP2 from './imgs/xfP2.png'
import xfP1 from './imgs/xfP1.png'
import ykrP2 from './imgs/ykrP2.png'
import ykrP1 from './imgs/ykrP1.png'
import inteP1 from './imgs/inteP1.png'
import ojyP1 from './imgs/ojyP1.png'
import inteP2 from './imgs/inteP2.png'
import ojyP2 from './imgs/ojyP2.png'
import zwsjP1 from './imgs/zwsjP1.png'
import {BackTop,Dropdown,Menu } from "antd"
import {baseUrl, companyName, weChat,contact,phone2,phone3} from '../../api/commonSrc'
import './header.css'

function Header(props){

    const buttonList = [
        {
            key: '首页',
            label:(<div onClick={()=>switchVideo('首页',2)}>首页</div>)
        },{
            key: '智慧市场',
            label:(<div onClick={()=>switchVideo('智慧市场',2)}>智慧市场</div>),
            children: [
                {
                    key: '互联网+明厨亮灶',
                    label:(<div onClick={()=>onclick('互联网+明厨亮灶',1)}>互联网+明厨亮灶</div>)
                },{
                    key: '智慧食安·透明工厂',
                    label:(<div onClick={()=>onclick('智慧食安·透明工厂',1)}>智慧食安·透明工厂</div>)
                },{
                    key: '智慧药安监管服务',
                    label:(<div onClick={()=>onclick('智慧药安监管服务',1)}>智慧药安监管服务</div>)
                },{
                    key: '特种设备一码多用监管',
                    label:(<div onClick={()=>onclick('特种设备一码多用监管',1)}>特种设备一码多用监管</div>)
                },{
                    key: '信用信息共享服务',
                    label:(<div onClick={()=>onclick('信用信息共享服务',1)}>信用信息共享服务</div>)
                },{
                    key: '协同办公流转助手',
                    label:(<div onClick={()=>onclick('协同办公流转助手',1)}>协同办公流转助手</div>)
                },{
                    key: '风险事件办理中心',
                    label:(<div onClick={()=>onclick('风险事件办理中心',1)}>风险事件办理中心</div>)
                },{
                    key: '案件案号内部协同平台',
                    label:(<div onClick={()=>onclick('案件案号内部协同平台',1)}>案件案号内部协同平台</div>)
                }]
        },{
            key: '校园安全',
            label:(<div onClick={()=>switchVideo('校园安全',2)}>校园安全</div>),
            children: [{
                key: '校园食安卫士',
                label:(<div onClick={()=>onclick('校园食安卫士',2)}>校园食安卫士</div>)
            }]
        },{
            key: '智慧审计',
            label:(<div onClick={()=>switchVideo('智慧审计',2)}>智慧审计</div>),
            children: [{
                key: '智慧审计文献服务平台',
                label:(<div onClick={()=>onclick('智慧审计文献服务平台',3)}>智慧审计文献服务平台</div>)
            }]
        },{
            key: '智慧乡村',
            label:(<div onClick={()=>switchVideo('智慧乡村',2)}>智慧乡村</div>),
            children: [{
                key: '党建服务',
                label:(<div onClick={()=>onclick('党建服务',4)}>党建服务</div>)
            },{
                key: '政务服务',
                label:(<div onClick={()=>onclick('政务服务',4)}>政务服务</div>)
            },{
                key: '基层治理',
                label:(<div onClick={()=>onclick('基层治理',4)}>基层治理</div>)
            },{
                key: '数字三农',
                label:(<div onClick={()=>onclick('数字三农',4)}>数字三农</div>)
            },{
                key: '产业融合',
                label:(<div onClick={()=>onclick('产业融合',4)}>产业融合</div>)
            },{
                key: '企业安环',
                label:(<div onClick={()=>onclick('企业安环',4)}>企业安环</div>)
            },{
                key: '民声民情',
                label:(<div onClick={()=>onclick('民声民情',4)}>民声民情</div>)
            },{
                key: '乡村生态',
                label:(<div onClick={()=>onclick('乡村生态',4)}>乡村生态</div>)
            }]
        },{
            key: '关于我们',
            label:(<div onClick={()=>switchVideo('关于我们',2)}>关于我们</div>),
        }]

        const buttonList1 = [
            {
                key: '首页',
                label:(<div onClick={()=>switchVideo('首页',2)}>首页</div>)
            },{
                key: '智慧市场',
                label:(<div onClick={()=>switchVideo('智慧市场',2)}>智慧市场</div>),
                children: [
                    {
                        key: '互联网+明厨亮灶',
                        label:(<div onClick={()=>onclick('互联网+明厨亮灶',1)}>互联网+明厨亮灶</div>)
                    },{
                        key: '智慧食安·透明工厂',
                        label:(<div onClick={()=>onclick('智慧食安·透明工厂',1)}>智慧食安·透明工厂</div>)
                    },{
                        key: '智慧药安监管服务',
                        label:(<div onClick={()=>onclick('智慧药安监管服务',1)}>智慧药安监管服务</div>)
                    },{
                        key: '特种设备一码多用监管',
                        label:(<div onClick={()=>onclick('特种设备一码多用监管',1)}>特种设备一码多用监管</div>)
                    },{
                        key: '信用信息共享服务',
                        label:(<div onClick={()=>onclick('信用信息共享服务',1)}>信用信息共享服务</div>)
                    },{
                        key: '协同办公流转助手',
                        label:(<div onClick={()=>onclick('协同办公流转助手',1)}>协同办公流转助手</div>)
                    },{
                        key: '风险事件办理中心',
                        label:(<div onClick={()=>onclick('风险事件办理中心',1)}>风险事件办理中心</div>)
                    },{
                        key: '案件案号内部协同平台',
                        label:(<div onClick={()=>onclick('案件案号内部协同平台',1)}>案件案号内部协同平台</div>)
                    }]
            },{
                key: '校园安全',
                label:(<div onClick={()=>switchVideo('校园安全',2)}>校园安全</div>),
                children: [{
                    key: '校园食安卫士',
                    label:(<div onClick={()=>onclick('校园食安卫士',2)}>校园食安卫士</div>)
                }]
            },{
                key: '智慧审计',
                label:(<div onClick={()=>switchVideo('智慧审计',2)}>智慧审计</div>),
                children: [{
                    key: '智慧审计文献服务平台',
                    label:(<div onClick={()=>onclick('智慧审计文献服务平台',3)}>智慧审计文献服务平台</div>)
                }]
            },{
                key: '智慧乡村',
                label:(<div onClick={()=>switchVideo('智慧乡村',2)}>智慧乡村</div>),
                children: [{
                    key: '党建服务',
                    label:(<div onClick={()=>onclick('党建服务',4)}>党建服务</div>)
                },{
                    key: '政务服务',
                    label:(<div onClick={()=>onclick('政务服务',4)}>政务服务</div>)
                },{
                    key: '基层治理',
                    label:(<div onClick={()=>onclick('基层治理',4)}>基层治理</div>)
                },{
                    key: '数字三农',
                    label:(<div onClick={()=>onclick('数字三农',4)}>数字三农</div>)
                },{
                    key: '产业融合',
                    label:(<div onClick={()=>onclick('产业融合',4)}>产业融合</div>)
                },{
                    key: '企业安环',
                    label:(<div onClick={()=>onclick('企业安环',4)}>企业安环</div>)
                },{
                    key: '民声民情',
                    label:(<div onClick={()=>onclick('民声民情',4)}>民声民情</div>)
                },{
                    key: '乡村生态',
                    label:(<div onClick={()=>onclick('乡村生态',4)}>乡村生态</div>)
                }]
            }]
   
    const [active,setActive] = useState(0)
    const [current, setCurrent] = useState('');
    const [lastActive,setLastActive] = useState(0)
    const [hover,setHover] = useState(false)
    const [hover1,setHover1] = useState(false)
    const [hover2,setHover2] = useState(false)
    const [items,setItems] = useState([])


    useEffect(()=>{
        setCurrent(props.current)
    },[props.current])
   
    const borderBg1 = {
        // height: '100%',
        // width:'100%',
        backgroundImage: 'url(' +border1 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition:"center",
        display: "none"
    };

    const borderBg2 = {
        // height: '100%',
        // width:'100%',
        backgroundImage: 'url(' +border1 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition:"center",
    };

    const borderBg3 = {
        // height: '100%',
        // width:'100%',
        backgroundImage: 'url(' +border2 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition:"center",
        display: "none"
    };

    const borderBg4 = {
        // height: '100%',
        // width:'100%',
        backgroundImage: 'url(' +border2 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition:"center",
    };

    const borderBg5 = {
        // height: '100%',
        // width:'100%',
        backgroundImage: 'url(' +border3 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition:"center",
        display: "none"
    };

    const borderBg6 = {
        // height: '100%',
        // width:'100%',
        backgroundImage: 'url(' +border3 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition:"center",
    };


    //一级标题跳转
    const switchVideo = (item,index) =>{
        if(item === '首页'){
            window.location.replace(baseUrl + '/#/home')
        }else if(item === '智慧市场'){
            window.location.replace(baseUrl + '/#/smartMarket')
        }else if(item === '校园安全'){
            window.location.replace(baseUrl + '/#/campusSecurity')
        }else if(item === '智慧审计'){
            window.location.replace(baseUrl + '/#/smartAudit')
        }else if(item === '智慧乡村'){
            window.location.replace(baseUrl + '/#/smartVillage')
        }else if(item === '关于我们'){
            window.location.replace(baseUrl + '/#/aboutUs')
        }
        setActive(index)
        setLastActive(index)
    }
    //两级标题跳转
    const onclick = (item,index) =>{
        if(item === '互联网+明厨亮灶'){
            window.location.replace(baseUrl + '/#/foodSafety')
        }else if (item === '智慧药安监管服务'){
            window.location.replace(baseUrl + '/#/drugSafety')
        }else if (item === '信用信息共享服务'){
            window.location.replace(baseUrl + '/#/informationSharing')
        }else if (item === '风险事件办理中心'){
            window.location.replace(baseUrl + '/#/riskHandling')
        }else if (item === '政务服务'){
            window.location.replace(baseUrl + '/#/convenience')
        }else if (item === '数字三农'){
            window.location.replace(baseUrl + '/#/digitalFarming')
        }else if (item === '企业安环'){
            window.location.replace(baseUrl + '/#/safetyRing')
        }else if (item === '乡村生态'){
            window.location.replace(baseUrl + '/#/ruralEcology')
        }else if(item === '民声民情'){
            window.location.replace(baseUrl + '/#/peopleslivelihood')
        }else if(item === '产业融合'){
            window.location.replace(baseUrl + '/#/industryAmalgamation')
        }else if(item === "基层治理"){
            window.location.replace(baseUrl + '/#/grassroots')
        }else if(item === "党建服务"){
            window.location.replace(baseUrl + '/#/partyBuilding')
        }else if(item === "智慧审计文献服务平台"){
            window.location.replace(baseUrl + '/#/auditLiterature')
        }else if(item === "校园食安卫士"){
            window.location.replace(baseUrl + '/#/foodSafetyGuard')
        }else if(item === "案件案号内部协同平台"){
            window.location.replace(baseUrl + '/#/caseNumber')
        }else if(item === "协同办公流转助手"){
            window.location.replace(baseUrl + '/#/coordinateOfficeFlow')
        }else if(item === "特种设备一码多用监管"){
            window.location.replace(baseUrl + '/#/specialEquipment')
        }else if(item === "智慧食安·透明工厂"){
            window.location.replace(baseUrl + '/#/transparentPlant')
        }
        setActive(index)
    }

    const onMouseEnter = (item,index1) => {
        // let list = []
        // if(item === '智慧市场'){
        //     list = ['互联网+明厨亮灶','智慧食安·透明工厂','智慧药安监管服务','特种设备一码多用监管','信用信息共享服务','协同办公流转助手','风险事件办理中心','案件案号内部协同平台']
        // }else if (item === '校园安全'){
        //     list = ['校园食安卫士']
        // }else if (item === '智慧审计'){
        //     list = ['智慧审计文献服务平台']
        // }else if(item === '智慧乡村'){
        //     list = ['党建服务','政务服务','基层治理','数字三农','产业融合','企业安环','民声民情','乡村生态']
        // }
        // let finallList = []
        // list.map((item,index)=>{
        //     let param = {}
        //     param.key = index+1
        //     param.label = (<div onClick={()=>onclick(item,index1)}>{item}</div>)
        //     finallList.push(param)
        // })
        // setItems(finallList)
        // // console.log(index)
        // // setActive(index)
    }

    

  const onClick = (e) => {
    console.log('click ', e);
    setCurrent(e.key);
  };
    return (
        <div className='header-box'>
            <div className='header-left'>
                <img style={companyName === '中国联合网络通信集团有限公司' ? {height:'80%',marginTop:'0.5rem'} : companyName === '青岛中微世纪信息科技有限公司' ?   {height:'80%',marginTop:'0.5rem'} : {}} className='header-img' src={companyName === '青岛安瑞信息技术有限公司' ? arLogo : companyName === '青岛世纪亿联信息科技有限公司' ? sjylLogo : 
                companyName === '青岛小飞智能科技工程有限公司' ? xfLogo: companyName === '济南云科睿信息科技有限公司' ? ykrLogo : companyName === '中国联合网络通信集团有限公司' ? ltLogo : 
                companyName === '青岛因特信息科技有限责任公司' ?  itxxLogo : companyName === '青岛欧嘉悦网络科技有限公司' ?  ojyLogo : companyName === '青岛中微世纪信息科技有限公司' ?  zwsjLogo : qlLogo }/>
            </div>
            <div className='header-right' style={{paddingTop:22}}>
            <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={companyName === '中国联合网络通信集团有限公司' ?  buttonList1 : buttonList} className='header-menu' subMenuOpenDelay={0.1}/>
                {/* {
                   
                    buttonList.map((item,index)=>{
                        return (
                            index == 0 || index == 5 ?
                            <div className={active===index ? 'header-right-font1' : 'header-right-font2'} onClick={()=>switchVideo(item.name,index)} onMouseEnter={()=>onMouseEnter(item.name,index)} >{item.name}</div> :
                            <Dropdown menu={{ items5 }} placement="bottom" arrow>
                                <div className={active===index ? 'header-right-font1' : 'header-right-font2'} onClick={()=>switchVideo(item.name,index)} onMouseEnter={()=>onMouseEnter(item.name,index)} >{item.name}</div>
                            </Dropdown> 
                        )
                    }) 
                } */}

                {/* <div className='header-right-font1'>首页</div> */}
            </div>
            {
                companyName === '中国联合网络通信集团有限公司' ? 
                <div className='header-right-icon'>
                        <BackTop>
                        <img src={top} className='right-icon3'/>
                        </BackTop>
                    </div> : 
                <div>
                    <div className='header-right-icon'>
                        <img src={hover?weixin1:weixin} className='right-icon1' onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)}/>
                        <img src={hover1?phone1:phone} className='right-icon1' onMouseEnter={()=>setHover1(true)} onMouseLeave={()=>setHover1(false)}/>
                        <img src={hover2?code1:code} className='right-icon2' onMouseEnter={()=>setHover2(true)} onMouseLeave={()=>setHover2(false)}/>
                        <BackTop>
                        <img src={top} className='right-icon3'/>
                        </BackTop>
                    </div>
                    <div className='header-right-icon1' style={hover?borderBg2:borderBg1}>
                        <div className='right-icon1-img1'><img src={companyName === '青岛安瑞信息技术有限公司' ? arWeCode : companyName === '青岛世纪亿联信息科技有限公司' ? sjylP1 : companyName === '青岛小飞智能科技工程有限公司' ? xfP1 : 
                        companyName === '济南云科睿信息科技有限公司' ? ykrP1 : companyName === '青岛因特信息科技有限责任公司' ? inteP1 : companyName === '青岛欧嘉悦网络科技有限公司' ? ojyP1 : 
                        companyName === '青岛中微世纪信息科技有限公司' ?  zwsjP1 : p1} style={{width:'100%',height:'100%'}}/></div>
                        <div className='right-icon1-font1'>微信扫一扫添加好友</div>
                        <div className='right-icon1-font2'>微信号：{weChat}</div>
                    </div>
                    <div className='header-right-icon2' style={hover1?borderBg4:borderBg3}>
                        <div className='right-icon2-font1'>服务时间：</div>
                        <div className='right-icon2-font2'>工作日早9：00-晚6：00</div>
                        <div className='right-icon2-font1'>联系人：</div>
                        <div className='right-icon2-font2'>{contact}</div>
                        <div className='right-icon2-font1'>联系电话：</div>
                        <div className='right-icon2-font2'>{phone3}</div>
                        <div className='right-icon2-font1' style={{fontSize:13,marginTop:10}}>以服务为基础；以质量为生存；<br/>以科技求发展。</div>
                    </div>
                    <div className='header-right-icon3' style={hover2?borderBg6:borderBg5}>
                        <div className='right-icon1-img1'><img src={companyName === '青岛安瑞信息技术有限公司' ? arP2 : companyName === '青岛世纪亿联信息科技有限公司' ? sjylP2 : companyName === '青岛小飞智能科技工程有限公司' ? xfP2 : 
                        companyName === '济南云科睿信息科技有限公司' ? ykrP2 : companyName === '青岛因特信息科技有限责任公司' ? inteP2 : companyName === '青岛欧嘉悦网络科技有限公司' ? ojyP2 : p2} style={{width:'100%',height:'100%'}}/></div>
                        <div className='right-icon1-font1'>微信扫一扫</div>
                        <div className='right-icon1-font2' style={{fontSize:14}}>{companyName}</div>
                    </div>
                </div>
            }
            
            
            
        </div>
    )
}

export default Header